@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
/*CSS Reset*/
* {
	margin: 0;
	padding: 0;
	border: 0;
}
/*Customizable Root Variables*/
:root {
/*Basic Customization*/
	--color-primary: #ffffff;
	--text-primary: #000000;
	--color-secondary: #43d859;
	--text-secondary: white;
	--color-accent: #FEAC24;
	--text-accent: #ffffff;
	
/*Advanced Customization*/
	--text-basis: 16px;
	--max-width: 1000px;
	--w-padding: calc(calc(100vw - var(--max-width)) / 2);

	--nav-color: var(--color-accent);
	--nav-text: var(--text-accent);
	--nav-text-hover: var(--text-accent);
	
	--button-color: var(--color-accent);
	--button-text: var(--text-accent);
	
	--blockquote-border: var(--color-accent);
	--blockquote-color: rgba(50,50,50,.075);
	--blockquote-text: var(--text-primary);
	
	--code-border: var(--color-accent);
	--code-color: rgba(50,50,50,.075);
	--code-text: var(--text-primary);
	
	--card-header-color: var(--color-accent);
	--card-header-text: var(--text-accent);
	--card-color: var(--color-primary);
	--card-text: var(--text-primary);
	--card-footer-color: var(--color-accent);
	--card-footer-text: var(--text-accent);
	
	--form-color: white;
	--form-text: black;
	
	--accordian-header-color: var(--color-accent);
	--accordian-header-text: var(--text-accent);
	
	--modal-color: var(--color-primary);
	--modal-text: var(--text-primary);
	
	--alert-color: var(--color-primary);
	--alert-text: var(--text-primary);
	
	--table-text: var(--text-primary);
	
	--footer-color: var(--color-primary);
	--footer-text: var(--text-primary);
}
@media (prefers-color-scheme: dark) {
  :root {
    --color-primary: #202020;
    --text-primary: #c0c0c0;
  }
}
/*BASIC ELEMENTS*/
html {
	font-size: var(--text-basis);
}
body { 
	background-color: var(--color-primary);
}
h1, h2, h3, h4, h5, h6, p, a, blockquote, tr, th, td, table, li, input, textarea, button {
	line-height: 1.5;
	font-family: 'Lato', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
	margin-top: 10px;
	font-weight: 400;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
	margin-top: 0;
}
h1 {
	font-size: 3rem
}
h2 {
	font-size: 2.25rem
}
h3 {
	font-size: 1.75rem
}	
h4  {
	font-size: 1.575rem
}
h5 {
	font-size: 1.35rem
}
h6 {
	font-size: 1.25rem
}
p {
	font-size: 1rem;
	margin: 0;
}
p + p {
	margin-top: 20px;
}
p img.left {
	margin-right: 20px;
}
p img.right {
	margin-left: 20px;
}
a, tr, td, th, table, li {
	font-size: 1rem;
}
blockquote, code {
	padding: 5px 10px;
	margin: 15px 0;
	display: block;
	max-width: 100%;
	box-sizing: border-box;
}
blockquote:first-child, code:first-child {
	margin-top: 0;
}
blockquote {
	border-left: 4px solid var(--blockquote-border);
	font-size: 1rem;
	font-style: italic;
	background-color: var(--blockquote-color);
	color: var(--blockquote-text);
}
code {
	font-family: monospace;
	border-left: 4px solid var(--code-border);
	font-size: 0.8rem;
	overflow: scroll;
	background-color: var(--code-color);
	color: var(--code-text);
}
hr {
	border-style: inset;
  border-width: 1px;
  border: 1px solid rgba(0,0,0,0.25);
}
li {
	margin-left: 20px;
}
/*
NAVIGATION
  - Mobile Top-Nav
*/
.nav.mobile {
	display: none;
}
.nav {
	width: 100%;
	background-color: var(--nav-color);
	color: var(--nav-text);
	padding: 0;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
	z-index: 1000;
	box-sizing: border-box;
}
.nav.nav-priority {
	z-index:6000;
}
.nav.top, .nav.sticky {
	top: 0;
	padding: 0 var(--w-padding);
	overflow-x: visible;
}
.nav.top {
	position: fixed;
	left: 0;
}
.nav.sticky {
	position: sticky;
}
.nav.flush-left {
	padding-left: 0;
}
.nav.flush-right {
	padding-right: 0;
}
.nav .item, .nav .title, .nav .nav-trigger, .nav a {
	color: inherit;
}
.nav .item, .nav .dropdown .title {
	display: inline-block;
	box-sizing: border-box;
	text-decoration: none;
	padding: 15px 20px;
	vertical-align: middle;
	line-height: 1;
	transition: 0.15s ease-in-out;
	opacity: 1;
}
.nav .item.active {
	opacity: 1;
	font-weight: 700;
}
.nav .item.active:hover {
	cursor: default;
}
.nav .item.disabled {
	opacity: 0.75;
}
.nav .item.disabled:hover {
	opacity: 0.75;
	cursor: default;
	background-color: inherit;
}
.nav > .title {
	display: inline-block;
	box-sizing: border-box;
	text-decoration: none;
	vertical-align: middle;
	line-height: 1;
	padding: 15px 20px;
	font-weight: 800;
	font-size: 1.15rem;
}
.nav.top > .title, .nav.sticky > .title {
	padding: 14px 20px;
}
.nav .icon {
	display:inline-block;
	height: 35px;
	padding: 0;
	margin: 0 20px;
	box-sizing: border-box;
	vertical-align: middle;
}
.nav .icon + .title {
	margin-left: -20px;
	padding-left: 0;
}
.nav.top.flush, .nav.sticky.flush {
	padding: 0;
}
.nav.top.flush-left, .nav.sticky.flush-left {
	padding-left: 0;
}
.nav.top.no-flush-right, .nav.sticky.flush-right {
	padding-right: 0;
}
.nav.top.nav-side, .nav.sticky.nav-side {
	padding-left: 335px;
}
.nav.top .item.active, .nav.sticky .item.active {
	font-weight: 700;
}
.nav.dark .item:hover, .nav.dark .dropdown:hover .title {
	background-color: rgba(0,0,0,0.1);
}
.nav .item:hover, .nav .dropdown:hover .title {
	background-color: rgba(255,255,255,0.25);
	opacity: 1;
	color: var(--nav-text-hover);
	cursor: pointer;
}
.nav .item.active:hover, .nav .item.disabled:hover {
	background-color: inherit;
	color: inherit;
	cursor: default;
}
/*- Side Navigation (Also Doubles as Mobile Hamburger Navigation)*/
.nav.side {
	width: 250px;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: scroll;
	overflow-x: visible;
}
.nav.side.nav-top {
	padding-top: 50px;
}
.nav.side a {
	display: block;
	box-sizing: border-box;
	text-decoration: none;
}
.nav.side .item, .nav.side .dropdown .title {
	margin: 0;
	padding: 10px 20px;
	background-color: rgba(0,0,0,0);
	transition: 0.25s ease-in-out;
}
.nav.side .item.active {
	font-weight: 700;
}
.nav.side .item:hover, .nav.side .dropdown:hover .title {
	background-color: rgba(255,255,255,0.25);
	padding-left: 25px;
}
.nav.side.dark .item:hover, .nav.side.dark .dropdown:hover .title {
	background-color: rgba(0,0,0,0.1);
}
.nav.side .item.active:hover, .nav.side .item.disabled:hover {
	background-color: inherit;
	color: inherit;
	padding-left: 20px;
	cursor: default;
}
.nav.side > .title {
	margin: 10px 0 0 0;
	padding: 10px 20px;
	font-size: 2rem;
	font-weight: 700;
}
.nav.side hr {
	border-color: rgba(255,255,255,0.5);
}
.nav.side.dark hr {
	border-color: rgba(0,0,0,0.25);
}
.nav.side.mobile-only {
	display: none;
}
.nav.side .account {
	padding: 15px 20px;
}
.nav.side.dark .account {
	border-bottom: 1px solid rgba(0,0,0,0.25);
}
.nav.side .account .avatar {
	width: 50px;
	height: 50px;
	border-radius: 25px;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.nav.side .account .info {
	width: 150px;
	padding: 0 10px;
	margin: 0;
	box-sizing: border-box;
}
.nav.side .account p, .nav.side .account .item {
	font-size: 0.9rem;
	padding: 0;
}
.nav.side .account .item {
	opacity: 0.6;
}
.nav.side .account .item:hover {
	padding-left: 0;
	opacity: 1;
	cursor: pointer;
	background-color: rgba(0,0,0,0);
}
/*- Dropdown specific CSS for all navigations*/
.nav .dropdown {
	position: relative;
	margin: 0;
	padding: 0;
	overflow: visible;
	display: inline-block;
}
.nav .dropdown .items {
	display: none;
	position: absolute;
	top: 48px;
	width: 100%;
	min-width: 150px;
	z-index: 2500;
	background-color: var(--color-accent);
	color: var(--text-accent);
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.3);
	border-top: 1px solid rgba(255,255,255,0.5);
}
.nav .dropdown:hover .items {
	display: block;
}
.nav .dropdown .items .item {
	padding: 7.5px 20px;
	background-color: rgba(255,255,255,0.125);
	width: 100%;
	box-sizing: border-box;
	display: block;
}
.nav .dropdown .items .item:hover {
	padding-left: 25px;
	background-color: rgba(255,255,255,0.25);
	cursor: pointer;
}
.nav .dropdown .items .item.disabled:hover {
	padding-left: 20px;
	background-color: rgba(255,255,255,0.125);
}
.nav.dark .dropdown .items{
	border-top: 1px solid rgba(0,0,0,0.25);
}
.nav.dark .dropdown .items .item {
	background-color: rgba(0,0,0,0.05);
}
.nav.dark .dropdown .items .item:hover {
	background-color: rgba(0,0,0,0.1);
}
.nav.dark .dropdown .items .item.disabled:hover {
	background-color: rgba(0,0,0,0.05);
}
.nav.side .dropdown {
	display: block;
}
.nav.side .dropdown .title {
	width: 100%;
	box-sizing: border-box;
}
.nav.side .dropdown .items {
	top: 0;
	left: 250px;
	width: 150px;
}
/*MAIN CONTENT*/
.main {
	background-color: none;
	color: var(--text-primary);
}
.main.nav-side {
	margin-left: 250px;
}
.main.nav-top {
	margin-top: calc(var(--text-basis) + 32px);
}
/*- Content Containers*/
.main > .header {
	margin: 0;
	padding: 75px calc(var(--w-padding) + 20px) 10px calc(var(--w-padding) + 20px);
	position: relative;
	box-sizing: border-box;
}
.main > .hero {
	padding: 150px calc(var(--w-padding) + 20px);
	background-position: 50% 50%;
	background-size: cover;
}
.main > .container {
	background-color: none;
	padding: 25px calc(var(--w-padding) + 20px);
}
.main.nav-side > .header, .main.nav-side > .hero, .main.nav-side > .container {
	padding-left: 10vw;
}
/*- Cards*/
.card {
	background-color: var(--card-color);
	color: var(--card-text);
	margin-bottom: 20px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
}
.card .header {
	padding: 15px 20px 5px 20px;
	background-color: var(--card-header-color);
	color: var(--card-header-text);
}
.card .content {
	padding: 10px 20px;
	background-color: var(--card-color);
	color: var(--card-text);
	position: relative;
}
.card img.content {
	padding: 0;
	margin: 0;
	width: 100%;
	margin: 0;
	display: block;
}
.card.rounded img.content {
	border-radius: 15px;
}
.card.rounded .header + .content {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.card.rounded .content:has(+ .footer) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.card .footer {
	padding: 5px 20px;
	background-color: var(--card-footer-color);
	color: var(--card-footer-text);
	width: 100%;
	box-sizing: border-box;
}
.card .header.small, .card .footer.small {
	padding: 5px 10px;
}
.card a.footer {
	margin: 0;
	padding: 5px 20px;
	width: 100%;
	cursor: pointer;
	display: block;
}
.card.secondary .header, .card .header.secondary, .card.secondary .footer, .card .footer.secondary {
	background-color: var(--color-secondary);
	color: var(--text-secondary);
}
.card.clickable {
	transition: 0.25s;
	position: relative;
	top: 0;
}
.card.clickable:hover {
	box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
	top: -5px;
	cursor: pointer;
}
.card.clickable .clicker {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
}
.card.rounded {
	border-radius: 15px;
}
.card.rounded .header {
	border-radius: 15px 15px 0 0;
}
.card.rounded .content {
	border-radius: 15px;
}
.card.rounded .footer {
	border-radius: 0 0 15px 15px;
}
.card .header.rounded {
	border-radius: 0 0 15px 15px;
}
.card.rounded .header.rounded {
	border-radius: 15px;
}
.card .content.rounded {
	border-radius: 15px;
}
.card .footer.rounded {
	border-radius: 15px 15px 0 0;
}
.card.rounded .footer.rounded {
	border-radius: 15px;
}
/*- Modals and Alerts*/
.modal, .alert {
	position: fixed;
	box-sizing: border-box;
	transition: 0.25s;
	opacity: 0;
	visibility: hidden;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
}
.modal {
  left: 50vw;
  top: 75vh;
  transform: translate(-50%, -50%);
	max-height: 75vh;
	width: 650px;
	background-color: var(--modal-color);
	color: var(--modal-text);
	z-index: 10000;
	overflow-y: scroll;
	padding: 25px 50px;
}
.modal.small {
	max-height: 60vh;
	width: 500px;
	padding: 20px 40px;
}
.modal.large {
	max-height: 85vh;
	width: 800px;
}
.modal.full {
	height: calc(100vh - 50px);
	width: calc(100vw - 25px);
}
.modal.activated {
	top: 50vh;
}
.alert {
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	width: 100vw;
	padding: 15px 35px;
	z-index: 9000;
	background-color: var(--alert-color);
	color: var(--alert-text);
}
.alert.warning {
	background-color: red;
	color: white;
	font-weight: 700;
}
.alert.activated, .modal.activated {
	visibility: visible;
	opacity: 1;
}
.modal .trigger, .alert .trigger {
	position: absolute;
	top: 0;
	right: 10px;
	padding: 0;
	margin: 0;
	line-height: 1;
	font-size: 2rem;
	cursor: pointer;
	opacity: 0.5;
	transition: 0.25s;
	font-weight: 700;
}
.modal .trigger:hover, .alert .trigger:hover {
	opacity: 1;
}
/*- Accordians*/
.accordion-header {
	margin: 0;
	padding: 10px 34px;
	background-color: var(--accordian-header-color);
	color: var(--accordian-header-text);
	box-sizing: border-box;
	transition: 0.8s;
	display: block;
	cursor: pointer;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
	position: relative;
}
.accordion-header:after {
	font-size: 1rem;
  content: "\25BC";
  padding: 10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  text-align: center;
  text-align: right;
  pointer-events: none;
  transition: 0.5s;
}
.accordion-header.active:after {
	transform:rotateX(180deg);
	top: 2px;
}
.accordion {
	overflow: hidden;
	transition: 0.65s;
	max-height: 0;
	box-sizing: border-box;
	padding: 0px 34px;
	transition: 1s;
}
.accordion.activated {
	padding: 20px 34px;
}
/*- Buttons*/
.button {
	background-color: var(--button-color);
	color: var(--button-text);
	position: relative;
	top: 0;
	display: inline-block;
	padding: 10px 20px;
	margin: 10px 0;
	margin-right: 25px;
	text-align: center;
	transition: 0.25s;
	text-decoration: none;
}
.button.small {
	padding: 5px 10px;
}
.button.large {
	padding: 15px 25px;
}
.button.column {
	margin-right: 0;
}
.button.rounded {
	border-radius: 10px;
}
.button.pill {
	border-radius: 9999px;
}
.button:hover {
	top: -5px;
	box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
	cursor: pointer;
}
.button:active {
	animation: button-click 0.25s ease-in-out;
}
.button.disabled {
	filter: brightness(80%);
	filter: grayscale(50%);
}
.button.disabled:hover {
	top: 0;
	box-shadow: none;
	cursor: default;
}
.button.flush {
	margin: 0;
}
.button.flush-right {
	margin-right: 0;
}
.button.flush-left {
	margin-left: 0;
}
@keyframes button-click {
	0% {filter: brightness(100%);}
	50% {filter: brightness(120%);}
	100% {filter: brightness(100%);}
}
/*- Tables*/
table {
	border-collapse: collapse;
	table-layout: fixed;
	padding: 0;
	width: 100%;
	margin: 25px 0;
}
th, td {
	text-align: left;
	border-bottom: 1px solid var(--table-text);
	background-color: none;
	padding: 5px 15px 5px 0;
	color: var(--table-text);
}
th {
	font-weight: 700;
	font-size: 0.85rem;
}
td {
	font-size: 0.95rem;
	font-weight: 300;
}
/*- Forms*/
input, textarea, select {
	width: 100%;
	padding: 5px 10px;
	margin: 5px 0;
	font-size: 0.85rem;
  box-sizing: border-box;
  border: 2px solid rgba(0,0,0,0.25);
  background-color: var(--form-color);
	color: var(--form-text);
}
textarea {
  height: 150px;
  resize: none;
}
textarea.small {
	height: 75px;
}
textarea.large {
	height: 250px;
}
button {
	position: relative;
	top: 0;
	transition: 0.25s;
	background-color: var(--color-accent);
	color: var(--text-accent);
	padding: 5px 10px;
	cursor: pointer;
	font-size: 1rem;
}
button:hover {
	top: -5px;
	box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
	cursor: pointer;
}
input:focus, textarea:focus, select:focus {
	outline: none;
	border: 2px solid var(--color-accent);
}
input.half, textarea.half, button.half, select.half {
	width: 50%;
	margin-right: 0;
}
/*Footers*/
.main > .footer {
	background-color: var(--footer-color);
	color: var(--footer-text);
	padding: 50px var(--w-padding);
	box-sizing: border-box;
	width: 100%;
	box-shadow: 0 -1px 5px 0 rgba(0,0,0,0.15);
}
.main.nav-side > .footer {
	padding-left: 10vw;
}
.main > .footer h1, .main > .footer h2, .main > .footer h3, .main > .footer h4, .main > .footer h5, .main > .footer h6 {
	line-height: 1;
	padding: 0;
}
.main > .footer h1, .main > .footer h2, .main > .footer h3 {
	font-weight: 700;
}
.main > .footer h1 {
	font-size: 1.75rem;
	margin-top: 0;
}
.main > .footer h2 {
	font-size: 1.5rem;
	margin-top: 0.25rem;
}
.main > .footer h3 {
	font-size: 1.125rem;
	margin-top: 0.625rem;
}
.main > .footer h4, .main > .footer h5, .main > .footer h6 {
	font-size: 1rem;
	font-weight: 400;
	font-style: italic;
	margin-top: 0.75rem;
}
.main > .footer .item {
	display: block;
	color: var(--footer-text);
	opacity: 0.75;
	text-decoration: none;
	margin: 5px 0;
	padding: 0;
	transition: 0.3s;
	box-sizing: border-box;
}
.main > .footer .item:hover {
	opacity: 1;
	cursor: pointer;
	padding-left: 5px;
}
.main > .footer .item.active {
	opacity: 0.6;
	font-weight: 700;
}
.main > .footer .item.active:hover {
	opacity: 0.6;
	padding-left: 0;
}
.main > .footer p {
	display: block;
	padding: 0;
	margin: 5px 0;
	color: var(--footer-text);
}
/*Utilities and Colors*/
	/*Floats and Clears*/
.left {
	float: left;
}
.right {
	float: right;
}
.clear-left {
	clear: left;
}
.clear-right {
	clear:right;
}
.clear {
	clear: both;
}
.clear::after {
	content: "";
  clear: both;
  display: table;
}
	/*Text Adjustments*/
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-just {
	text-align: justify;
}
.text-compact {
	line-height: 1.15;
}
.text-weight-800 {
	font-weight: 800;
}
.text-weight-700 {
	font-weight: 700;
}
.text-weight-400 {
	font-weight: 400;
}
.text-weight-300 {
	font-weight: 300;
}

.text-small, .text-small * {
	font-size: 90%;
}
.text-large, .text-large * {
	font-size: 150%;
}
	/*Flex-based Columns and Footer Sizing*/
.columns, .main > .footer {
	display: -webkit-flex;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
}
.columns .column, .main > .footer .column {
	flex: 1 1 0;
	min-width: 0;
}
.columns .column.double, .main > .footer .column.double {
	flex: 2 1 0;
}
.columns .column.triple, .main > .footer .column.triple {
	flex: 2 1 0;
}
.columns .column + .column, .main > .footer .column + .column {
	margin-left: 50px;
}
* + .columns {
	margin-top: 10px;
}
	/*Utility Columns*/
.full, .half, .third, .two-thirds, .fourth, .three-fouths {
	box-sizing: border-box;
	margin-right: 25px;
}
.full {
	width: calc(100% - 25px);
}
.half {
	width: calc(50% - 25px);
}
.third {
	width: calc(33.33% - 25px);
}
.two-thirds {
	width: calc(66.66% - 25px);
}
.fourth {
	width: calc(25% - 25px);
}
.three-fourths {
	width: calc(75% - 25px);
}
	/*Color Utilities*/
*.primary{
	background-color: var(--color-primary);
	color: var(--text-primary);
}
*.accent {
	background-color: var(--color-accent);
	color: var(--text-accent);
}
*.secondary {
	background-color: var(--color-secondary);
	color: var(--text-secondary);
}
*.text-primary {
	color: var(--text-primary);
}
*.text-accent {
	color: var(--text-accent);
}
*.text-secondary {
	color: var(--text-secondary);
}
*.color-primary {
	color: var(--color-primary);
}
*.color-secondary {
	color: var(--color-secondary);
}
*.color-accent {
	color: var(--color-accent);
}
*.inv-primary{
	color: var(--color-primary);
	background-color: var(--text-primary);
}
*.inv-accent {
	color: var(--color-accent);
	background-color: var(--text-accent);
}
*.inv-secondary {
	color: var(--color-secondary);
	background-color: var(--text-secondary);
}
*.text-inv-primary {
	color: var(--color-primary);
}
*.text-inv-accent {
	color: var(--color-accent);
}
*.text-inv-secondary {
	color: var(--color-secondary);
}
*.color-inv-primary {
	color: var(--text-primary);
}
*.color-inv-secondary {
	color: var(--text-secondary);
}
*.color-inv-accent {
	color: var(--text-accent);
}
/*Other*/
.no-wrap {
	white-space: nowrap;
}
.mobile-only {
	display: none;
}
.spacer {
	height: 50px;
}
.spacer.small {
	height: 25px;
}
.spacer.large {
	height: 75px;
}
.square {
	border-radius: 0;
}
.rounded {
	border-radius: 10px;
}
.pill {
	border-radius: 9999px;
}
.outlined {
	border: 2px solid rgba(0,0,0,0.25);
}
.shadow {
	position: relative;
	box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
}
.z-priority {
	z-index: 1000;
}
img.full-height {
	height: 100%;
	width: auto;
}
.middle {
	vertical-align: middle;
}
.columns.middle {
	align-items: center;
}
/*General Alignment*/
*.flush {
	margin-left: 0;
	margin-right: 0;
}
*.flush-left {
	margin-left: 0;
}
*.flush-right {
	margin-right: 0;
}
.align-bottom {
	vertical-align: bottom;
}
@media screen and (max-width: 850px) {
	h1 {
		font-size: 2.5rem;
	}
	h2 {
		font-size: 2rem;
	}
	h3 {
		font-size: 1.625rem;
	}
	h4 {
		font-size: 1.5;
	}
	h5 {
		font-size: 1.375;
	}
	h6 {
		font-size: 1.25;
	}
	.no-tablet {
		display: none;
	}
	.nav.top.nav-side, .nav.sticky.nav-side {
		margin-left: 250px;
	}
	.nav.side {
		width: 250px;
	}
	.main.nav-side {
		margin-left: 250px;
	}
	.main > .header {
		padding: 75px 25px 17.5px 25px;
	}
	.main .container {
		padding: 25px;
	}
	table {
		margin: 10px 0;
		width: 100%;
		box-sizing: border-box;
		overflow-x: scroll;
		table-layout: auto;
		display: block;
		background-color: rgba(0,0,0,0.05);
	}
	td, th {
		font-size: 0.8rem;
		padding: 5px 10px;
		/*max-width: 50px;*/
	}
	.full, .half, .third, .two-thirds, .fourth, .three-fourths {
		margin-left: 0;
		margin-right: 10px;
	}
	.full {
		width: calc(100% - 10px);
	}
	.half {
		width: calc(50% - 10px);
	}
	.third {
		width: calc(33.33% - 10px);
	}
	.two-thirds {
		width: calc(66.66% - 10px);
	}
	.fourth {
		width: calc(25% - 10px);
	}
	.three-fourths {
		width: calc(75% - 10px);
	}
	blockquote {
		width: 100%;
		background-color: rgba(0,0,0,0.05);
		box-sizing: border-box;
		font-size: 1rem;
	}
	code {
		width: 100%;
		background-color: rgba(0,0,0,0.05);
		box-sizing: border-box;
		font-size: 0.85rem;
	}
	.modal {
		max-height: calc(100vh - 200px);
		width: calc(100vw - 50px);
		padding: 25px 15px;
	}
	.modal.large {
		max-height: calc(100vh - 100px);
		width: calc(100vw - 50px);
	}
	.modal.small {
		max-height: calc(100vh - 400px);
		width: calc(100vw - 50px);
	}
	.modal.full {
		max-height: calc(100vh - 50px);
		width: calc(100vw - 50px);
	}
}
@media screen and (max-width: 700px) {
	.mobile-only {
		display: block;
	}
	.no-mobile {
		display: none !important;
	}
	h1 {
		font-size: 2rem;
	}
	h2 {
		font-size: 1.625rem;
	}
	h3 {
		font-size: 1.475rem;
	}
	h4 {
		font-size: 1.35rem;
	}
	h5 {
		font-size: 1.25rem;
	}
	h6 {
		font-size: 1.125rem;
	}
	.nav.mobile {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		z-index: 1000;
		background-color: var(--nav-color);
		color: var(--nav-text);
		box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
	}
	.nav.mobile .title {
		vertical-align: baseline;
	}
	.nav.mobile * {
		display: inline-block;
		padding: 10px;
		color: var(--nav-text);
		font-size: 1.25rem;
	}
	.nav.mobile .nav-trigger {
		padding: 10px 5px 10px 15px;
	}
	.nav-trigger:hover {
		cursor: pointer;
	}
	.nav.top, .nav.sticky {
		display: none;
	}
	.nav.side {
		position: fixed;
		top: 50px;
		left: -300px;
		transition: 1s;
		background-color: white;
		color: black;
		z-index: 900;
	}
	.nav.side.active {
		left: 0;
	}
	.nav.side .item, .nav.side .title {
		color: black;
	}
	.nav.side .item:hover {
		background-color: rgba(0,0,0,0.05);
	}
	.nav.side .item.active {
		background-color: rgba(0,0,0,0.05);
	}
	.nav.side .item.active:hover {
		background-color: rgba(0,0,0,0.05);
	}
	.nav.side hr {
		border: 1px solid rgba(0,0,0,0.25);
	}
	.nav.side .spacer {
		height: 25px;
	}
	.nav.side.mobile-only {
		display: block;
	}
	.nav.side.no-mobile {
		display: none;
	}
	.main.nav-side {
		margin-left: 0;
	}
	.main.m-nav-top {
		margin-top: 50px;
	}
	.main > .header {
		padding: 25px 15px 5px 15px;
	}
	.main > .hero {
		padding: 100px 15px;
	}
	.main .container {
		padding: 15px;
	}
	.main.nav-side > .header, .main.nav-side > .hero, .main.nav-side > .container {
		padding-left: 15px;
	}
	.card {
		margin: 0;
		width: 100%;
	}
	.main > .footer {
		padding: 20px 50px;
	}
	.columns, .main > .footer {
		display: block;
		width: 100%;
		box-sizing: border-box;
	}
	.columns .column, .main > .footer .column {
		display: block;
		width: 100%;
		margin: 0;
		box-sizing: border-box;
	}
	.columns .column + .column, .main > .footer .column + .column {
		margin: 0;
		margin-top: 20px;
	}
	.main > .footer .column + .column {
		margin-top: 20px;
	}
	.column.card + .column.card {
		margin-top: 10px;
	}
	input, textarea, select {
		padding: 5px 10px;
		margin: 5px 0;
	}
	.footer {
		padding: 25px 20px;
	}
	.m-full, .m-half, .m-third, .m-two-thirds, .m-fourth, .m-three-fourths {
		margin-top: 5px;
		margin-bottom: 0;
	}
	.m-full {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
	img.m-full, img.m-half, img.m-third, img.m-two-thirds, img.m-fourth, img.m-three-fourths {
		height: auto;
	}
	.m-half {
		margin-left: 0;
		margin-right: 0;
		width: 50%;
	}
	.m-third {
		margin-left: 0;
		margin-right: 0;
		width: 33%;
	}
	.m-two-thirds {
		margin-left: 0;
		margin-right: 0;
		width: 66%;
	}
	.m-fourth {
		margin-left: 0;
		margin-right: 0;
		width: 25%;
	}
	.m-three-fourths {
		margin-left: 0;
		margin-right: 0;
		width: 75%;
	}
	.modal .trigger, .alert .trigger {
		opacity: 1;
	}
}
